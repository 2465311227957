<template>
  <div>
    <v-card>
      <v-card-title class="pt-0 pb-0">
        <span class="title-page">REQUISIÇÃO DE PESSOAL</span>

        <v-spacer />

        <v-text-field
          v-model="filterData"
          class="mt-6"
          label="Buscar por requisitante, função, status, aprovado"
          outlined
          dense
          @keyup.enter="findFilterResult"
        />

        <v-btn
          color="info"
          class="ml-4"
          outlined
          @click="handleOpenModalRequest"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          NOVA REQUISIÇÃO
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="listOfFilteredItems"
        :loading="isLoadingData"
        loading-text="Carregando dados..."
      >
        <template v-slot:no-data>
          <span>Sem informações para exibir</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-alert
            class="badge"
            :color="getStatusColor(item)"
            text
          >
            {{ item.status ? item.status : '' }}
          </v-alert>
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon
            medium
            class="me-2"
            color="warning"
            @click="handleOpenModalRequestEdit(item)"
          >
            {{ icons.mdiEye }}
          </v-icon>
        </template>
        <template v-slot:item.occupation.name="{ item }">
          <div

            class="d-flex flex-column align-start"
            style="gap: 10px;"
          >
            <span>{{ item.occupation.name }}</span>
            <div
              v-if="!!item.hired_person_name"
              class="d-flex"
              style="gap: 9px;"
            >
              <v-tooltip
                top
                color="#1E1E1E"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="success"
                    size="20px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiAccountOutline }}
                  </v-icon>
                </template>

                <span>Candidato classificado</span>
              </v-tooltip>
              <span style="text-align: left;">{{ item.hired_person_name.toUpperCase() }}</span>
            </div>
          </div>
        </template>

        <template v-slot:item.creator.name="{ item }">
          <div
            class="d-flex"
            style="gap: 5px;"
          >
            <span>
              {{ item.creator.name ? item.creator.name : '' }}
            </span>
            <div>
              <v-tooltip
                v-if="leadersVotesDiverge(item) || returnedToRH(item)"
                color="orange"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    color="orange"
                    size="20"
                    style="position: absolute; z-index: 1; margin-left: -8px;"
                    class="pb-5"
                    v-on="on"
                  >
                    {{ icons.mdiExclamationThick }}
                  </v-icon>
                </template>
                <span>Importante</span>
              </v-tooltip>
            </div>
          </div>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ dateFormat(item.created_at) }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showModalRequest"
      width="600px"
    >
      <ModalPersonRequest
        @close="showModalRequest = false, dataModalView.id = ''"
        @updatedTable="updatedTable"
      ></ModalPersonRequest>
    </v-dialog>

    <v-dialog
      v-model="showModalRequestEdit"
      width="600px"
    >
      <ModalPersonRequestEdit
        :key="dataModalView.id"
        :data="dataModalView"
        :close-modal-edit="handleCloseModalEdit"
        :profile="userProfile"
        @close="showModalRequestEdit = false"
        @updatedTable="updatedTable"
      ></ModalPersonRequestEdit>
    </v-dialog>

    <v-dialog
      v-model="showModalRD"
      width="700px"
    >
      <ModalRD
        :id="dataModalView.id"
        :key="modalRdKey"
        :data="dataModalView"
        :close-modal-rd="handleCloseModalRD"
        :profile="userProfile"
        @close="showModalRD = false"
        @updatedTable="updatedTable"
      ></ModalRD>
    </v-dialog>

    <v-dialog
      v-model="showModalWaitingLeader"
      width="700px"
    >
      <ModalWaitingLeader
        :key="dataModalView.id"
        :data="dataModalView"
        :close-modal-rd="handleCloseModalWaitingLeader"
        :profile="userProfile"
        @close="showModalWaitingLeader = false"
        @updatedTable="updatedTable"
      ></ModalWaitingLeader>
    </v-dialog>

    <v-dialog
      v-model="showModalRequestCanceled"
      width="700px"
    >
      <ModalRequestCanceled
        :key="dataModalView.id"
        :data="dataModalView"
        :profile="userProfile"
        @close="showModalRequestCanceled = false"
        @updatedTable="updatedTable"
      ></ModalRequestCanceled>
    </v-dialog>

    <v-dialog
      v-model="showModalRequestWaitingCancellation"
      width="700px"
    >
      <ModalRequestWaitingCancellation
        :key="dataModalView.id"
        :data="dataModalView"
        @close="showModalRequestWaitingCancellation = false"
        @updatedTable="updatedTable"
      ></ModalRequestWaitingCancellation>
    </v-dialog>

    <v-dialog
      v-model="showModalWaitingApproval"
      width="600"
    >
      <ModalWaitingApproval
        :key="dataModalView.id"
        :profile="userProfile"
        :level="userLevel"
        :data="dataModalView"
        @close="showModalWaitingApproval = false"
        @updatedTable="updatedTable"
      >
      </ModalWaitingApproval>
    </v-dialog>
    <v-dialog
      v-model="showModalStadBy"
      width="700px"
    >
      <ModalStadBy
        :key="modalStandby"
        :data="dataModalView"
        :profile="userProfile"
        @changeRdKey="changeModalRdKey"
        @close="showModalStadBy = false"
        @updatedTable="updatedTable"
      ></ModalStadBy>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/plugins/axios'
import {
  mdiAccountOutline,
  mdiClipboardText,
  mdiExclamationThick,
  mdiEye, mdiFilterPlusOutline, mdiFilterRemoveOutline,
  mdiPlaylistPlus,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import ModalPersonRequest from './ModalPersonRequest.vue'
import ModalPersonRequestEdit from './ModalPersonRequestEdit.vue'
import ModalRD from './ModalRD.vue'
import ModalRequestCanceled from './ModalRequestCanceled.vue'
import ModalRequestWaitingCancellation from './ModalRequestWaitingCancellation.vue'
import ModalStadBy from './ModalStadBy.vue'
import ModalWaitingApproval from './ModalWaitingApproval.vue'
import ModalWaitingLeader from './ModalWaitingLeader.vue'

export default {
  components: {
    ModalWaitingLeader,
    ModalPersonRequest,
    ModalPersonRequestEdit,
    ModalRD,
    ModalRequestCanceled,
    ModalRequestWaitingCancellation,
    ModalStadBy,
    ModalWaitingApproval,
  },

  data() {
    return {
      filterData: '',
      showModalWaitingLeader: false,
      showModalStadBy: false,
      showModalWaitingApproval: false,
      showModalRequestWaitingCancellation: false,
      showModalRD: false,
      showModalRequestCanceled: false,
      dataModalView: {},
      showModalRequest: false,
      showModalRequestEdit: false,

      modalRdKey: Math.random(),
      modalStandby: Math.random(),

      icons: {
        mdiEye,
        mdiPlaylistPlus,
        mdiClipboardText,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
        mdiExclamationThick,
        mdiAccountOutline,
      },

      listOfFilteredItems: [],
      isLoadingData: false,
      itemsTable: [],

      headers: [
        {
          text: 'REQUISITANTE',
          value: 'creator.name',
          sortable: false,
          align: 'left',
        },
        {
          text: 'LOJA',
          value: 'creator.company.city',
          sortable: false,
          align: 'left',
        },
        {
          text: 'DATA',
          value: 'created_at',
          sortable: true,
          align: 'left',
        },
        {
          text: 'SETOR',
          value: 'sector.name',
          sortable: false,
          align: 'left',
        },
        {
          text: 'FUNÇÃO SOLICITADA/APROVADO',
          value: 'occupation.name',
          sortable: false,
          align: 'left',
        },
        {
          text: 'STATUS',
          value: 'status',
          sortable: true,
          align: 'center',
        },
        {
          text: 'VISUALIZAR',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ],

      userProfile: '',
      userLevel: '',
      userId: '',
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  async created() {
    this.handleGetData()

    const userProfiles = localStorageSlim.get('userProfile', { decrypt: true })

    this.userProfile = userProfiles.profile.profile
    this.userId = userProfiles.id
    this.userLevel = userProfiles.hierarchical_profile
  },

  methods: {
    changeModalRdKey() {
      this.modalRdKey = Math.random()
    },
    changeModalStandBy() {
      this.modalStandby = Math.random()
    },
    leadersVotesDiverge(item) {
      if (!item.alert) {
        return false
      }

      return item.alert.vote && item.status === 'AGUARDANDO RD' && this.userProfile === 'Diretor'
    },
    returnedToRH(item) {
      if (!item.alert) {
        return false
      }

      return item.alert.disqualification && ((item.status === 'EM ANDAMENTO' && this.userProfile === 'Recursos Humanos') || (item.status === 'AGUARDANDO LÍDER' && (this.userId === item.created_by) || (item.status === 'AGUARDANDO DP' && this.userProfile === 'Departamento Pessoal')))
    },
    handleCloseModalRD() {
      this.showModalRD = false
    },

    handleCloseModalWaitingLeader() {
      this.showModalWaitingLeader = false
    },

    handleCloseModalEdit() {
      this.showModalRequestEdit = false
    },

    async handleGetData() {
      this.isLoadingData = true

      try {
        const response = await axiosIns.get('/api/v1/personnel_request/employee_request/index')

        this.listOfFilteredItems = response.data.data
        this.itemsTable = response.data.data
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoadingData = false
      }
    },

    dateFormat(date) {
      const dateSliced = date.slice(0, 10)

      const hour = date.slice(11, 19)

      const dateDivided = dateSliced.split('-')

      const dateFormatted = `${dateDivided[2]}/${dateDivided[1]}/${dateDivided[0]} - ${hour}`

      return dateFormatted
    },

    getStatusColor(item = { status: '' }) {
      const { status } = item

      const statusColorMap = {
        'AGUARDANDO RH': 'yellowGold',
        'AGUARDANDO DP': 'blue',
        'AGUARDANDO RD': 'info',
        'AGUARDANDO DIRETORIA': 'gray',
        'AGUARDANDO ENCERRAMENTO': 'greenWeak',
        'AGUARDANDO APROVAÇÃO': 'greenAvocado',
        'AGUARDANDO LÍDER': 'lightPink',
        'EM ANDAMENTO': 'orange',
        'STAND-BY': 'purple',
        CONCLUÍDO: 'success',
        STANDBY: 'purple',
        CANCELADO: 'error',
      }

      return statusColorMap[status] ?? ''
    },

    handleOpenModalRequest() {
      this.showModalRequest = true
    },

    handleOpenModalRequestEdit(data) {
      this.changeModalStandBy()
      this.changeModalRdKey()
      const { status } = data

      this.dataModalView = data

      const statusOpenModal = [
        'AGUARDANDO RD',
        'AGUARDANDO DIRETORIA',
        'EM ANDAMENTO',
        'AGUARDANDO DP',
        'AGUARDANDO ENCERRAMENTO',
        'CONCLUÍDO',
      ]

      if (statusOpenModal.includes(status)) {
        this.showModalRD = true
      }

      if (status === 'AGUARDANDO RH') {
        this.showModalRequestEdit = true
      }

      if (status === 'CANCELADO') {
        this.showModalRequestCanceled = true
      }

      if (status === 'AGUARDANDO CANCELAMENTO') {
        this.showModalRequestWaitingCancellation = true
      }

      if (status === 'AGUARDANDO APROVAÇÃO') {
        this.showModalWaitingApproval = true
      }

      if (status === 'STAND-BY') {
        this.showModalStadBy = true
      }

      if (status === 'AGUARDANDO LÍDER') {
        this.showModalWaitingLeader = true
      }
    },

    updatedTable() {
      this.handleGetData()
    },

    findFilterResult() {
      const { itemsTable, filterData } = this
      const filterDataLower = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(
        item => {
          const { name: nameCreator } = item.creator
          const { name } = item.occupation
          const status = item.status.toLowerCase()
          const hiredPersonNameLowed = item.hired_person_name ? item.hired_person_name.toLowerCase() : ''
          const nameLowed = name.toLowerCase()
          const creatorLowed = nameCreator.toLowerCase()

          return status.includes(filterDataLower) || hiredPersonNameLowed.includes(filterDataLower) || nameLowed.includes(filterDataLower) || creatorLowed.includes(filterDataLower)
        },
      )
    },
  },
}
</script>

<style lang="scss">
  .badge {
  margin-top: 16px;
  font-size: 13px;
  height: 40px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-page{
  font-size: 15px;
}
</style>
